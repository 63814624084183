import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./css/App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initApp } from "./redux/actions/AppAction";
import "bootstrap/dist/css/bootstrap.min.css";

const AppScreen = React.lazy(() => import("./screens/AppScreen"));
const HomeScreen = React.lazy(() => import("./screens/Home/HomeScreen"));
const ArchitectScreen = React.lazy(() =>
  import("./screens/Architect/ArchitectScreen")
);
const ProductDetailScreen = React.lazy(() =>
  import("./screens/Product/Details/ProductDetailsScreen")
);
const ProductListingScreen = React.lazy(() =>
  import("./screens/Product/Listing/ProductListingsScreen")
);
const MyWishListScreen = React.lazy(() =>
  import("./screens/Wish-List/MyWishListScreen")
);
const MyOrderScreen = React.lazy(() =>
  import("./screens/MyOrder/MyOrderScreen")
);
const LivingRoomScreen = React.lazy(() =>
  import("./screens/LivingRoom/LivingRoomScreen")
);
const ShoppingCartScreen = React.lazy(() =>
  import("./screens/ShoppingCart/ShoppingCartScreen")
);
const DeliveryInfoScreen = React.lazy(() =>
  import("./screens/DeliveryInformation/DeliveryInfoScreen")
);
const ShoppingOrderScreeen = React.lazy(() =>
  import("./screens/ShoppingOrder/ShoppingOrderScreen")
);
const PaymentScreen = React.lazy(() =>
  import("./screens/Payment/PaymentScreen")
);
const SetScreen = React.lazy(() => import("./screens/Set/SetScreen"));
const RoomScreen = React.lazy(() => import("./screens/Rooms/RoomSets"));
const LoyaltyProgramScreen = React.lazy(() =>
  import("./screens/LoyaltyProgram/LoyaltyProgramScreen")
);
const UpcomingProductScreen = React.lazy(() =>
  import("./screens/UpcomingProduct/UpcomingProductScreen")
);

const QuotationScreen = React.lazy(() =>
  import("./screens/Quotation/QuotationScreen")
);
const QuotationPreviewScreen = React.lazy(() =>
  import(
    "./screens/Quotation/Component/QuotationPreview/QuotationPreviewScreen"
  )
);

const AboutUsScreen = React.lazy(() =>
  import("./screens/AboutUs/AboutUsScreen")
);
const ShippingDetailsScreen = React.lazy(() =>
  import("./screens/ShippingDetails/ShippingDetails")
);
const MyAccountScreen = React.lazy(() =>
  import("./screens/MyAccount/MyAccountScreen")
);
const PersonalInfromationScreen = React.lazy(() =>
  import("./screens/PersonalInformation/PersonalInformationScreen")
);
const MyAddressScreen = React.lazy(() =>
  import("./screens/MyAddress/MyAddressScreen")
);
const NotFoundPage = React.lazy(() => import("./screens/NotFoundPage"));

const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.app);
  useEffect(() => {
    if (location.pathname !== "/login" && !isLoggedIn) {
      navigate("/login");
    }
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location, isLoggedIn, navigate]);
  return (
    <>
      <Outlet />
    </>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (!init) {
      setInit(true);
      dispatch(initApp());
    }
  }, [dispatch, init]);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route exact path="/" element={<HomeScreen />} />
            <Route exact path="/app" element={<AppScreen />} />
            <Route exact path="/architect" element={<ArchitectScreen />} />
            <Route
              exact
              path="/product/:productId"
              element={<ProductDetailScreen />}
            />
            <Route
              exact
              path="/product-list"
              element={<ProductListingScreen />}
            />
            <Route exact path="/my-wish-list" element={<MyWishListScreen />} />
            <Route exact path="/my-order" element={<MyOrderScreen />}></Route>
          </Route>
          <Route exact path="/living-room" element={<LivingRoomScreen />} />
          <Route exact path="/shopping-cart" element={<ShoppingCartScreen />} />
          <Route exact path="/delivery" element={<DeliveryInfoScreen />} />
          <Route
            exact
            path="/order-summary"
            element={<ShoppingOrderScreeen />}
          />
          <Route exact path="payment" element={<PaymentScreen />} />
          <Route exact path="/set" element={<SetScreen />} />
          <Route exact path="/rooms" element={<RoomScreen />} />
          <Route
            exact
            path="/loyaltyprogram"
            element={<LoyaltyProgramScreen />}
          />
          <Route
            exact
            path="/upcoming-product"
            element={<UpcomingProductScreen />}
          />
          <Route exact path="/Quotation" element={<QuotationScreen />} />
          <Route
            exact
            path="/Quotation/Quotationpreview"
            element={<QuotationPreviewScreen />}
          />

          <Route exact path="*" element={<NotFoundPage />} />
          <Route exact path="/about-us" element={<AboutUsScreen />}></Route>
          <Route
            exact
            path="/shipping-details"
            element={<ShippingDetailsScreen />}
          ></Route>
          <Route exact path="/my-account" element={<MyAccountScreen />}></Route>
          <Route
            exact
            path="personal-information"
            element={<PersonalInfromationScreen />}
          ></Route>
          <Route exact path="/my-address" element={<MyAddressScreen />}></Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </Suspense>
  );
};

export default App;
